<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                </div>
                <!-- <vs-input type="email" label-placeholder="Email" v-model="value1" class="w-full mb-6" /> -->

                <vs-input
                  v-validate="'required|min:7|max:50'"
                  ref="password"
                  type="password"
                  data-vv-name="password"
                  label-placeholder="Password"
                  v-model="password"
                  class="w-full mb-6"
                />
                <vs-input
                  v-validate="'required|confirmed:password'"
                  type="password"
                  label-placeholder="Confirm Password"
                  data-vv-name="password_confirmation"
                  data-vv-as="password"
                  v-model="password_confirmation"
                  class="w-full"
                />
                <div class="w-full">
                  <span class="text-danger text-sm" v-show="errors.has('password')"
                    >{{ errors.first('password') }}
                  </span>
                </div>
                <div class="w-full mb-8">
                  <span class="text-danger text-sm" v-show="errors.has('password_confirmation')"
                    >{{ errors.first('password_confirmation') }}
                  </span>
                </div>
                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <vs-button type="border" to="/pages/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >Go Back To Login</vs-button
                  >
                  <vs-button v-if="password_reset_token" v-on:click="resetPassword" class="w-full sm:w-auto"
                    >Reset</vs-button
                  >
                  <vs-button v-else v-on:click="resetPassword" disabled class="w-full sm:w-auto">Reset</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   email: '',
      password: '',
      password_confirmation: '',
      password_reset_token: this.$route.query.token,
    }
  },
  created() {},
  methods: {
    async isEmailValid() {
      return await this.$validator.validate()
    },
    resetPassword() {
      const payload = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        password_reset_token: this.password_reset_token,
      }
      this.$store
        .dispatch('auth/newPassword', payload)
        .then(() => {
          this.$router.push('/pages/login').catch(() => {})
          this.$vs.notify({
            title: 'Success',
            text: 'Password reset successfull',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        })
        .catch(error => {
          //   this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
}
</script>
